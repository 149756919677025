import { store } from "@/store"
import {
  deleteCart,
  removeFromCartLineItem,
  setCart,
  setCartCount,
  setShipping,
  shipmentCart,
  shipmentUpdate,
  createCart,
  recentlyViewed,
  setNoshipping,
  setCardStatus,
  getCart,
  breakBundle,
  setVersion,
} from "@/store/features/cartSlice"
import { getProfile } from "@/store/features/checkoutSlice"
import { apim } from "@/constants/api"
import {
  getShippingDiscountPromoCodes,
  arrayMove,
  getProductDetails,
  getDefaultProfileAddress,
  stateCodeToName,
} from "@/utils/helper"
import { INSTALL_SERVICES } from "@/utils/constants"
import _get from "lodash/get"

import { removeFromCart, setRemoveCartPayload } from "@/utils/removeCart"
import { getQuantityCount } from "@/utils/cart"
import { showToast } from "@/store/features/genericSlice"
import { inventoryStatus } from "@/utils/constants"

const setShippingAddress = async (data, errorCb) => {
  if (data.postalCode && data.postalCode.trim() !== "") {
    const address = {
      postalCode: data.postalCode?.trim(),
      region: data.state?.trim(),
      country: data.country?.trim(),
    }
    const {
      firstName,
      lastName,
      streetName,
      streetNumber,
      city,
      email,
      phone,
    } = data
    if (firstName) address.firstName = firstName.trim()
    if (lastName) address.lastName = lastName.trim()
    if (streetName) address.streetName = streetName.trim()
    if (streetNumber) address.streetNumber = streetNumber.trim()
    if (city) address.city = city?.trim()
    if (email) address.email = email.trim().toLowerCase()
    if (phone) address.phone = phone.trim()
    const actions = [
      {
        action: "setShippingAddress",
        address: address,
      },
    ]
    const payload = {
      actions: actions,
    }
    await store
      .dispatch(shipmentUpdate(payload))
      .unwrap()
      .then(async res => {
        if (res?.type === "Cart" && res.lineItems.length > 0) {
          await store.dispatch(setCart(res))
          const lineItems = res.lineItems || []
          const customLineItems = res.customLineItems || []
          const payload = getQuantityCount([...lineItems, ...customLineItems])
          store.dispatch(setCartCount(payload))
        }
      })
      .catch(error => {
        errorCb && errorCb(error)
      })
  }
}

const setBillingAddress = async (data, cb, needAnalytics, errorCb) => {
  const { user = {} } = store.getState()?.auth
  const isIscUser = user?.persona?.includes("IN_Kohler_ISC") || false
  if (data.postalCode && data.postalCode.trim() !== "") {
    const address = {
      postalCode: data.postalCode?.trim(),
      region: data.state?.trim(),
      country: data.country?.trim(),
    }
    const cart = store?.getState()?.cart?.cart
    const {
      firstName,
      lastName,
      streetName,
      streetNumber,
      city,
      email,
      phone,
    } = data
    if (firstName) address.firstName = firstName.trim()
    if (lastName) address.lastName = lastName.trim()
    if (streetName) address.streetName = streetName.trim()
    if (streetNumber) address.streetNumber = streetNumber.trim()
    if (city) address.city = city?.trim()
    if (email) address.email = email.trim().toLowerCase()
    if (phone) address.phone = phone.trim()
    const actions = [
      {
        action: "setBillingAddress",
        address: address,
      },
      {
        action: "calculateTax",
      },
    ]
    if (user?.koc) {
      actions.push({
        action: "setCustomType",
        type: { key: "order", typeId: "type" },
        fields: { CSRAssociateId: user?.koc, orderType: "ISC" },
      })
    }
    if (isIscUser && (!cart.customerEmail || cart?.customerEmail === "")) {
      actions.push({
        action: "setCustomerEmail",
        email: address.email,
      })
    }
    const payload = {
      actions: actions,
    }
    await store
      .dispatch(shipmentUpdate(payload))
      .unwrap()
      .then(async res => {
        if (res?.type === "Cart" && res.lineItems.length > 0) {
          await store.dispatch(setCart(res))
          const lineItems = res.lineItems || []
          const customLineItems = res.customLineItems || []
          const payload = getQuantityCount([...lineItems, ...customLineItems])
          store.dispatch(setCartCount(payload))
          // getShippingMethods()
          cb && cb(address.postalCode, "success")
          needAnalytics && needAnalytics("success")
        } else {
          if (!phone) {
            cb && cb(address.postalCode, "failure", "no items to ship in cart")
          }
        }
      })
      .catch(error => {
        errorCb && errorCb(error)
        if (!phone) {
          cb &&
            cb(address.postalCode, "failure", "failed to set shipping address.")
        }
      })
  }
}

// const getShippingMethods = async () => {
//   const cart = store?.getState()?.cart?.cart
//   const lineItemsLength = cart?.lineItems ? cart.lineItems.length : 0
//   // const taxedPrice = getState().cart?.cart?.data?.taxedPrice;
//   const shippingZip = localStorage.getItem("shippingZip")
//   const {
//     taxedPrice = "",
//     shippingAddress: { postalCode: shippingAddressPostCode = "" } = {},
//   } = cart
//   let shippingCost = 0
//   shippingCost = Number((cart?.shippingTotal?.centAmount ?? 0) / 100).toFixed(2)
//   let promoCodes = []
//   if (cart?.customLineItems?.length > 0) {
//     promoCodes = promoCodes.concat(await getShippingDiscountPromoCodes(cart))
//     promoCodes
//       .filter(item => item?.type === "shipping")
//       .forEach(item => {
//         shippingCost = Number(shippingCost) + Number(item.discount)
//       })
//   }
//   if (
//     lineItemsLength > 0 &&
//     shippingZip &&
//     shippingZip.trim() !== "" &&
//     shippingAddressPostCode &&
//     (!taxedPrice || !shippingCost || (shippingCost && shippingCost * 1 === 0))
//   ) {
//     await store
//       .dispatch(shipmentCart())
//       .unwrap()
//       .then(response => {
//         if (response && response["shipping-details"]) {
//           updateProductDetailToStore(response)
//         } else {
//           getShippingDetails()
//             .then(response => {
//               updateProductDetailToStore(response)
//             })
//             .catch(error => {
//               console.error(error)
//             })
//         }
//       })
//   }
// }

const getProfileData = async (errorMsg = "") => {
  let profile = {}
  try {
    const res = await store.dispatch(getProfile())
    if (res && res.data) {
      localStorage.setItem("profile-version", res.data?.version)
      profile = res.data
      profile.postalCode = profile?.addresses[0]?.postalCode
      const profileAddr = profile?.addresses?.find(
        addr => addr.custom?.fields?.label === "Profile"
      )
      profile.profileAddrId = profileAddr?.id
      profile.addresses = profile?.addresses?.filter(
        addr => addr.custom?.fields?.label !== "Profile"
      )
      let addresses = profile?.addresses
      if (profile?.defaultShippingAddressId) {
        const index = addresses.findIndex(
          item => item.id === profile.defaultShippingAddressId
        )
        addresses = arrayMove(profile?.addresses, index, 0)
      }
      profile.addresses = addresses
    }
  } catch (err) {
    errorMsg &&
      store.dispatch(
        showToast({
          message: errorMsg,
          isVisible: true,
        })
      )
  }
  return profile
}

export const removeCartItem = async (data, cb = null, isBundleParent) => {
  let payload
  const cart = store?.getState()?.cart?.cart
  const { isInstallService, installServiceName, isInstallItem } =
    getProductDetails(cart, data?.sku, isBundleParent)
  const installServiceData = cart?.lineItems?.find(
    item =>
      item.variant.sku === installServiceName &&
      item?.custom?.fields?.associatedProduct === data.sku
  )
  const tempData =
    isInstallService && installServiceData ? [data, installServiceData] : data
  const { shippingAddress: { postalCode = "" } = {} } = cart
  if (!Array.isArray(tempData)) {
    payload = removeFromCart(tempData)
    if (isInstallItem) {
      if (data?.productData) {
        const {
          installServiceName,
          lineItemId: productLineItemId,
          warehouses,
          installServiceQty,
        } = getProductDetails(cart, data?.productData?.sku)
        const currentInstallationQty = installServiceQty - data?.quantity
        if (currentInstallationQty > 0) {
          payload.push({
            action: "setLineItemCustomType",
            lineItemId: productLineItemId,
            type: {
              key: "cartLineItemAdditionalInfo",
              typeId: "type",
            },
            fields: {
              associatedService: installServiceName,
              associatedServiceQty: installServiceQty - data?.quantity,
              warehouses,
            },
          })
        } else {
          payload.push({
            action: "setLineItemCustomType",
            lineItemId: productLineItemId,
            type: {
              key: "cartLineItemAdditionalInfo",
              typeId: "type",
            },
            fields: {
              warehouses,
            },
          })
        }
      }
    }
  } else {
    payload = setRemoveCartPayload(tempData)
  }
  payload.push({
    action: "calculateTax",
  })
  const body = {
    actions: payload,
  }
  await store
    .dispatch(removeFromCartLineItem(body))
    .unwrap()
    .then(res => {
      store.dispatch(setCart(res))
      const lineItems = res.lineItems || []
      const customLineItems = res.customLineItems || []
      const payload = getQuantityCount([...lineItems, ...customLineItems])
      store.dispatch(setCartCount(payload))
      store.dispatch(setCardStatus(false))
      if (res.lineItems.length > 0) {
        if (postalCode) {
          // getShippingMethods()
        }
      } else {
        deleteFromCart(cb)
      }
    })
}

export const breakBundleItem = async id => {
  await store
    .dispatch(breakBundle(id))
    .unwrap()
    .then(res => {
      store.dispatch(setCart(res))
      store.dispatch(setCardStatus(false))
    })
    .catch(err => console.log(err))
}

export const removeLineItems = async (lineItems, productData = {}) => {
  const cart = store?.getState()?.cart?.cart
  const { id = "", version = 0, lineItems: cartItems } = cart
  const actions = []
  lineItems.forEach(item => {
    const isInstallItem = Object?.entries(item?.custom?.fields)?.length === 0
    if (isInstallItem) {
      if (productData) {
        const cartItem = cartItems?.find(
          item => item.variant.sku === productData?.sku
        )
        const productLineItemId = cartItem?.id
        const isInstallService =
          cartItem?.custom?.fields.hasOwnProperty("associatedService")
        const installServiceName =
          isInstallService && cartItem?.custom?.fields?.associatedService
        const warehouses =
          isInstallService && cartItem?.custom?.fields?.warehouses
        actions.push({
          action: "setLineItemCustomType",
          lineItemId: productLineItemId,
          type: {
            key: "cartLineItemAdditionalInfo",
            typeId: "type",
          },
          fields: {
            associatedService: installServiceName,
            associatedServiceQty: 0,
            warehouses,
          },
        })
      }
    }
    actions.push({
      action: item.isComboSku ? "removebundle" : "removeLineItem",
      lineItemId: item.id,
      quantity: item.quantity,
    })
  })
  const res = await apim.post(`/cart/items?cartId=${id}&expand=calculation`, {
    version: version,
    actions: actions,
  })
  if (res.status === 200) {
    store.dispatch(setCart(res.data))
    const lineItems = res.data.lineItems || []
    const customLineItems = res.data.customLineItems || []
    const payload = getQuantityCount([...lineItems, ...customLineItems])
    store.dispatch(setCartCount(payload))
    if (!quantityLineItems?.length) {
      deleteFromCart()
    }
  }
}

export const removeInstallItems = async (installItems = []) => {
  const cart = store?.getState()?.cart?.cart
  const { id = "", version = 0, lineItems: cartItems } = cart
  const actions = []
  installItems.forEach(installItem => {
    if (installItem.associatedProductSku) {
      const productCartItem = cartItems?.find(
        item => item.variant.sku === installItem.associatedProductSku
      )
      const productLineItemId = productCartItem?.id
      const isInstallService =
        productCartItem?.custom?.fields.hasOwnProperty("associatedService")
      const installServiceName =
        isInstallService && productCartItem?.custom?.fields?.associatedService
      const warehouses =
        isInstallService && productCartItem?.custom?.fields?.warehouses
      actions.push({
        action: "setLineItemCustomType",
        lineItemId: productLineItemId,
        type: {
          key: "cartLineItemAdditionalInfo",
          typeId: "type",
        },
        fields: {
          associatedService: installServiceName,
          associatedServiceQty: 0,
          warehouses,
        },
      })
    }
    actions.push({
      action: installItem.isComboSku ? "removebundle" : "removeLineItem",
      lineItemId: installItem.id,
      quantity: installItem.quantity,
    })
  })
  const res = await apim.post(`/cart/items?cartId=${id}&expand=calculation`, {
    version: version,
    actions: actions,
  })
  if (res.status === 200) {
    await store.dispatch(setCart(res.data))
    const lineItems = res.data.lineItems || []
    const customLineItems = res.data.customLineItems || []
    const payload = getQuantityCount([...lineItems, ...customLineItems])
    await store.dispatch(setCartCount(payload))
  }
}

export const clearCartFromStore = (email = "") => {
  store.dispatch(setCart({ lineItems: [] }))
  store.dispatch(setNoshipping([]))
  store.dispatch(setCartCount(0))
  store.dispatch(setShipping([]))
  const cartPayload = {
    currency: "INR",
  }
  const { user = {} } = store.getState()?.auth
  const isIscUser = user?.persona?.includes("IN_Kohler_ISC") || false
  if (!isIscUser) {
    cartPayload.customerEmail = email
  }
  store.dispatch(createCart(cartPayload))
}

export const deleteFromCart = async cb => {
  await store
    .dispatch(deleteCart())
    .unwrap()
    .then(res => {
      if (res) {
        cb && cb("success", null, res ? res : {})
        clearCartFromStore()
        const cartPayload = {
          currency: "INR",
        }
        store.dispatch(createCart(cartPayload))
      }
    })
    .catch(() => {
      cb && cb("failure", "failed to empty your cart.")
    })
}
const getProductsById = async ids => {
  let query = ""
  ids.forEach((id, i) => {
    query += `"${id}"`
    if (i + 1 !== ids.length) query += ", "
  })
  try {
    const response = await store
      .dispatch(recentlyViewed(query))
      .unwrap()
      .then(res => {
        if (res?.results) {
          return res.results
        }
      })
    if (response) return response
  } catch (err) {
    console.error("Failed to fetch product details", err)
  }
  return []
}

const getShippingDetails = async () => {
  return new Promise(async (resolve, reject) => {
    const cart = store?.getState()?.cart?.cart
    const shippingDetails = {
      "shipping-details": { results: [] },
      shippingCost: true,
    }
    const customLineItems = cart?.customLineItems || []
    const lineItems = cart?.lineItems || []

    const items = [...customLineItems, ...lineItems]
    const results = items
      .filter(item => {
        const productCategory = _get(
          item?.variant?.attributes.find(
            attr => attr.name === "ProductLocalCategory"
          ),
          "value",
          ""
        )
        return (
          (item.custom?.fields?.isBundleChildItem &&
            productCategory === INSTALL_SERVICES) ||
          !item.custom?.fields?.isBundleChildItem
        )
      })
      .map(({ variant, id }) => {
        return {
          type: "Parcel",
          tiers: [
            {
              name: "Standard",
              key: "Regular",
              currencyCode: "IN",
            },
          ],
          sku: variant?.sku,
          lineItemId: id,
        }
      })
    shippingDetails["shipping-details"]["results"] = results

    if (results) {
      resolve(shippingDetails)
    } else {
      reject(new Error("Cannot ship to your location"))
    }
  })
}

export const updateProductDetailToStore = response => {
  let validProducts = []
  let inValidProducts = []
  validProducts = response["shipping-details"]?.results?.filter(
    item => !item.warningMessage
  )
  inValidProducts = response["shipping-details"]?.results?.filter(
    item => item.warningMessage
  )
  store.dispatch(setShipping(validProducts))
  store.dispatch(setNoshipping(inValidProducts))
}

export const getMessageStatus = info => {
  const {
    data,
    email,
    hideAlerts,
    i18n,
    checkoutMode,
    isMobile,
    alert = {},
  } = info

  if (data.cancelled || email || !hideAlerts) {
    if (isMobile) {
      if (alert.show) {
        return true
      } else if (!alert.show && data.outOfStock) {
        return true
      } else if (!alert.show && !data.outOfStock && data.noShipping) {
        return true
      } else if (
        data.category !== "Install Services" &&
        data.category !== "Design Services"
      ) {
        if (
          data.backOrder &&
          data.leadTime &&
          data.quantity > data.availableQty
        ) {
          return true
        } else if (!alert.show) {
          if (data.outOfStock || data.noShipping) {
            return false
          } else {
            return true
          }
        } else {
          return false
        }
      } else return false
    } else {
      return true
    }
  } else if (
    !data.cancelled &&
    hideAlerts &&
    data.backOrder &&
    data.leadTime &&
    data.quantity > data.availableQty &&
    i18n.backOrderMssg
  ) {
    return true
  } else if (!checkoutMode && !isMobile) {
    return true
  } else {
    return false
  }
}

export const getPincode = async () => {
  try {
    const getCookieValue = name =>
      document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() ||
      ""
    return getCookieValue("pincode")
  } catch (e) {
    console.log(e)
  }
}

export const checkInventory = async (
  isCheckout,
  pin,
  updateCartVersion = false
) => {
  try {
    let inventoryData = {}
    const cart = store?.getState()?.cart?.cart
    const pincode = pin ?? (await getPincode())
    const body = {
      version: cart?.version,
      actions: [
        {
          action: "checkInventory",
        },
      ],
    }
    if (pincode) {
      const {
        data,
        data: { newLineItems = [], version },
      } = await apim.post(
        `/cart/items?cartId=${cart?.id}&expand=calculation&pincode=${pincode}`,
        body
      )
      if (updateCartVersion && version) {
        await store.dispatch(setVersion(version))
        // await store.dispatch(setCart(data))
      }
      if (isCheckout) {
        return newLineItems
      } else {
        newLineItems?.map(
          ({ error, latestInventory, sku, isParentQtyFullfilled }) => {
            if (error && latestInventory?.length === 0) {
              inventoryData[sku] = 0
            } else {
              if (!isParentQtyFullfilled) {
                const availableQty = latestInventory?.reduce(
                  (acc, curr) => acc + curr.availableQuantity,
                  0
                )
                inventoryData[sku] = availableQty
              }
            }
          }
        )
        return { inventoryData, newLineItems }
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export async function getETA(
  sku,
  mastervariantkey,
  quantity,
  postalCode,
  isReturnResponse = false,
  isBundle = false,
  handleError = () => {}
) {
  try {
    const pincode = postalCode || (await getPincode())

    const payload = {
      pincode,
      qty: quantity,
    }
    if (isBundle) {
      payload.masterKey = sku
      payload.parentSKU = sku
    } else {
      payload.mastervariantkey = mastervariantkey
      payload.sku = sku
    }

    const { data = {} } = await apim.post(
      "/catalog/stock-availability/eta" + (isBundle ? "?type=bundle" : ""),
      payload
    )
    if (isReturnResponse) {
      return data.result?.data
    }
    if (isBundle) {
      if (data.status === "fail") {
        handleError()
        return
      }
      return [JSON.stringify(data.result?.data)]
    }

    const { result: { data: { eta = {}, warehouse = [] } = {} } = {} } = data
    let wareHouse
    if (Object.keys(eta)?.length > 0) {
      if (warehouse?.length) {
        wareHouse = [JSON.stringify(warehouse)]
      }
    }
    return wareHouse
  } catch (e) {
    console.log(e)
  }
}

const getTotalInclusiveTax = () => {
  const cart = store?.getState()?.cart?.cart
  let totalTax = 0
  let isError = false
  try {
    cart?.lineItems.forEach(items => {
      const currentLineItemCustomFields = items?.custom?.fields
      if (
        currentLineItemCustomFields &&
        "taxAmount" in currentLineItemCustomFields
      ) {
        totalTax += Number(currentLineItemCustomFields.taxAmount)
      } else if (Object.keys(currentLineItemCustomFields).length !== 0) {
        isError = true
      }
    })
  } catch (error) {
    isError = true
  }
  return isError ? null : totalTax
}

export const calculateInventoryStatus = newLineItems => {
  let installationError = false
  let stockData = []
  let nonDeliverableData = []
  let outOfStockData = []
  let installationServiceData = []

  stockData = newLineItems.map(
    ({
      isParentQtyFullfilled,
      sku,
      error,
      installationService,
      lineItemId,
      isOutOfStock,
      isNonDeliverable,
    }) => {
      if (error) {
        let associatedServiceSku = "",
          associatedServiceId = ""
        if (installationService && Object.keys(installationService).length) {
          if (!installationService.isInstallation) {
            associatedServiceSku = installationService?.name
            associatedServiceId =
              installationService?.associatedInstallServiceLineItemId
          }
        }
        if (isNonDeliverable) {
          nonDeliverableData.push({ sku, id: lineItemId })
          if (associatedServiceSku) {
            nonDeliverableData.push({
              sku: associatedServiceSku,
              id: associatedServiceId,
            })
          }
        } else if (isOutOfStock) {
          outOfStockData.push({ sku, id: lineItemId })
          if (associatedServiceSku) {
            outOfStockData.push({
              sku: associatedServiceSku,
              id: associatedServiceId,
            })
          }
        }
        return false
      } else {
        if (isParentQtyFullfilled) {
          const cart = store.getState().cart.cart
          const cartItem = cart.lineItems?.find(item => item.id === lineItemId)
          const hasInstallationAdded =
            cartItem?.custom?.fields?.associatedService &&
            cartItem.custom?.fields.associatedServiceQty

          if (
            hasInstallationAdded &&
            installationService &&
            Object.keys(installationService).length
          ) {
            const associatedServiceQty =
              cartItem.custom?.fields.associatedServiceQty
            const isInstallationAvailable = installationService?.isInstallation
            installationError = installationError || !isInstallationAvailable

            if (!isInstallationAvailable) {
              installationServiceData.push({
                id: installationService?.associatedInstallServiceLineItemId,
                sku: installationService.name,
                userQuantity: associatedServiceQty,
                associatedProductSku: sku,
                associatedProductId: cartItem.id,
              })
            }
            return isInstallationAvailable
          } else {
            return true
          }
        } else {
          return false
        }
      }
    }
  )
  return {
    stockData,
    nonDeliverableData,
    outOfStockData,
    installationError,
    installationServiceData,
  }
}

export const getInventoryStatus = (
  data,
  inventoryData,
  isInstallService = false
) => {
  const {
    nonDeliverableData = [],
    outOfStockData = [],
    installationServiceData = [],
  } = inventoryData
  if (
    nonDeliverableData.length &&
    nonDeliverableData.some(item => item.id === data.id)
  ) {
    return inventoryStatus.NON_DELIVERABLE
  } else if (
    outOfStockData.length &&
    outOfStockData.some(item => item.id === data.id)
  ) {
    return inventoryStatus.OUT_OF_STOCK
  } else if (
    installationServiceData.some(item =>
      isInstallService
        ? item.id === data.id
        : item.associatedProductId === data.id
    )
  ) {
    return inventoryStatus.INSTALLATION_NOT_AVAILABLE
  }
  return ""
}

export const getInventoryErrorMessage = (
  status,
  i18n,
  isInstallService = false
) => {
  switch (status) {
    case inventoryStatus.NON_DELIVERABLE:
      return `${i18n.nonDeliverableMsg} ${i18n.removeFromCart}`
    case inventoryStatus.OUT_OF_STOCK:
      return i18n.outOfStock
    case inventoryStatus.INSTALLATION_NOT_AVAILABLE:
      if (isInstallService) {
        return `${i18n?.installServiceNotAvailable} ${i18n.removeFromCart}`
      }
      return i18n.installServiceNotAvailable
    default:
      return ""
  }
}

export {
  setShippingAddress,
  setBillingAddress,
  getProfileData,
  getProductsById,
  getShippingDetails,
  getTotalInclusiveTax,
}
