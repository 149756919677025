export const convertToPhoneNumber = text => {
  try {
    if (text) {
      const value = text
      const x = value.replace(/\D/g, "")
      return x
    } else {
      return
    }
  } catch (error) {
    console.error(error)
  }
}

export * from "../src/js/helperV2"