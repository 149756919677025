import { COMPONENT_TYPES, brandTransactSwitcher } from "@/constants"
import { getConfig } from "@/constants/config"

const isImage = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.IMAGEV3)
}

const isAemImage = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.IMAGE)
}

const isAemList = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.LIST)
}

const isCartLanding = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.CART_LANDING)
}
const isFindAStore = item => {
  return item["templateName"] === COMPONENT_TYPES.FIND_A_STORE
}
const isPressLanding = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.PRESS_LANDING)
}
const isDealerEnquiry = item => {
  return item["dealershipTitle"]?.includes(COMPONENT_TYPES.DEALER_ENQUIRY)
}
const isProductEnquiry = item => {
  return item["productEnquiryTitle"]?.includes(COMPONENT_TYPES.PRODUCT_ENQUIRY)
}

const isBlogPage = item => {
  return (
    item[":type"]?.includes(COMPONENT_TYPES.BLOG_PAGE) &&
    !item[":type"]?.includes("blogtabpage")
  )
}

const isReturns = item => {
  return (
    item["title"]
      ?.toLowerCase()
      .includes(COMPONENT_TYPES.RETURNS.toLowerCase()) ||
    item["title"]
      ?.toLowerCase()
      .includes(COMPONENT_TYPES.RETURN_CONFIRMATION.toLowerCase())
  )
}

const isBrowseAllStores = item => {
  return (
    item[":type"]?.includes(COMPONENT_TYPES.BROWSE_ALL_STORES) ||
    item[":type"]?.includes(COMPONENT_TYPES.STORE_FILTER)
  )
}

const isCustomerSupport = item => {
  return item[":type"]?.includes(COMPONENT_TYPES?.CUSTOMER_SUPPORT)
}

const getPresetUrl = (
  width = 0,
  rowCols = 0,
  swatchUrl = "",
  assetId = "",
  presetConfigs = {}
) => {
  const {
    Desktop,
    MobilePortrait,
    MobilePortrait1,
    MobilePortrait2,
    MobileLandscape,
    MobileLandscape1,
    MobileLandscape2,
    PotraitCategory,
  } = presetConfigs
  let preset = ""
  if (width <= 768) {
    if (width <= 425) {
      if (rowCols) {
        if (rowCols === 1) preset = MobilePortrait1
        else preset = MobilePortrait2
      } else if (MobilePortrait) {
        preset = MobilePortrait
      } else preset = MobilePortrait2
    } else {
      if (rowCols) {
        if (rowCols === 1) preset = MobileLandscape1
        else preset = MobileLandscape2
      } else if (MobileLandscape) {
        preset = MobileLandscape
      } else preset = MobileLandscape1
    }
  } else preset = Desktop
  return `${swatchUrl}${PotraitCategory}?$product_src=is{PAWEB/${assetId}}&${preset}`
}

const isFooter = data => {
  const types = []
  data[":itemsOrder"] &&
    data[":itemsOrder"].forEach(key => {
      const type = data[":items"][key][":type"]
      if (type) {
        types.push(type)
      }
    })
  return (
    types.find(a => a.includes(COMPONENT_TYPES.MAIN_FOOTER)) ||
    types.find(a => a.includes(COMPONENT_TYPES.ADDITIONAL_FOOTER)) ||
    types.find(a => a.includes(COMPONENT_TYPES.NEWSLETTER_SIGNIN)) ||
    (types.find(a => a.includes(COMPONENT_TYPES.TEASER))
      ? undefined
      : types.find(a => a.includes(COMPONENT_TYPES.PRODUCT_DETAILS_ACCORDION)))
  )
}

export const getBillingAsShippingAddress = (shipping, billing) => {
  const {
    billingStreetName = "",
    billingAptNo = "",
    billingCity = "",
    billingStateCode = "",
    billingZipcode = "",
  } = billing
  const {
    shippingStreetName = "",
    shippingAptNo = "",
    shippingCity = "",
    shippingStateCode = "",
    shippingZipcode = "",
  } = shipping

  return (
    billingStreetName.toLowerCase() === shippingStreetName.toLowerCase() &&
    billingAptNo.toLowerCase() === shippingAptNo.toLowerCase() &&
    billingCity.toLowerCase() === shippingCity.toLowerCase() &&
    billingStateCode.toLowerCase() === shippingStateCode.toLowerCase() &&
    billingZipcode.toLowerCase() === shippingZipcode.toLowerCase()
  )
}

const isNullOrUndefined = value => {
  return value === null || value === undefined
}

const getPresetThumbnailUrl = (
  swatchUrl,
  assetId,
  presetConfigs,
  assetAccountName,
  imageUrl
) => {
  if (imageUrl && !imageUrl.includes("/PAWEB/")) {
    return imageUrl
  }
  const { Thumbnail, LandscapeCategory } = presetConfigs
  return `${swatchUrl}${LandscapeCategory}?$product_src=is{${assetAccountName}/${assetId}}&${Thumbnail}`
}

export const getBrandSwitcher = (headerData = {}) => {
  const headPropsItems = headerData[":items"]
  const headerPropsRoot = headPropsItems?.root
  const headerRootItems = headerPropsRoot?.[":items"]
  const hedaerPropsContainer = headerRootItems?.container
  const headerContainerItems = hedaerPropsContainer?.[":items"]
  const headerUtilityWidgets = headerContainerItems?.brand_switcher
  return headerUtilityWidgets?.items ?? ""
}

export const isBrandTransact = () => {
  const hostname = typeof window !== "undefined" ? window.location.hostname : ''
  const envhost = brandTransactSwitcher?.toUpperCase()
  if (hostname?.toUpperCase() == envhost) return true
  else return false
}

export const getOrderDetailsUrl = async (email, orderNumber, source) => {
  const config = await getConfig()
  const { general: { orderDetailsPath = "" } = {} } = config
  return `${orderDetailsPath}?orderNumber=${orderNumber}&q=${Buffer.from(
    email
  ).toString("base64")}${source ? "&orderSource="+source:""}`
}

export * from "../src/utils"
export {
  isFindAStore,
  isDealerEnquiry,
  isProductEnquiry,
  isImage,
  isAemImage,
  isAemList,
  isCartLanding,
  getPresetUrl,
  isCustomerSupport,
  isBrowseAllStores,
  isFooter,
  isReturns,
  isNullOrUndefined,
  getPresetThumbnailUrl,
  isPressLanding,
  isBlogPage,
}
